(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-registry/assets/javascripts/betslip-registry.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-registry/assets/javascripts/betslip-registry.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useEffect,
  useRef,
  useState,
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  analytics
} = svs.components;
const {
  Drawer
} = svs.components.lbUi;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  betslipForceTypes
} = svs.components.sport.tipsenShared;
const {
  useComponentFromRegistry
} = svs.components.tipsen.componentRegistry;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  CenterToScreen
} = svs.components.tipsen.centerToScreen;
const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const {
  selectDisplayName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectCouponSignCount
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  BetslipDrawerContent
} = svs.components.tipsen.betslipDrawerContent;
const {
  BetslipButton,
  MenuButton,
  useGetBetslipForceType,
  useIsScrolling
} = svs.components.tipsen.betslipRegistry;
const {
  selectCouponStatus
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  COUPON_VALID
} = svs.components.tipsen.engine.constants.couponStatus;
const BETSLIP_BASE_HEIGHT_MOBILE = 87;
const BETSLIP_BASE_HEIGHT_MOBILE_ONGOING = 65;
const DRAWER_TAB_HEIGHT = 10;
const isIOSWithSafari = svs.core.detect.os.iOS() && svs.core.detect.browser.safari();
const BetslipRegistry = () => {
  const betslipContainerRef = useRef(null);
  const BetslipSelector = useComponentFromRegistry('betslip-selector');
  const betslipForceType = useGetBetslipForceType();
  const couponId = useCouponId();
  const displayName = useSelector(selectDisplayName);
  const couponSignCount = useSelector(state => selectCouponSignCount(state, couponId));
  const isExtraSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const isSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));

  const isScrolling = useIsScrolling(600);
  const preventDrawerTransitionOnIOSWithSafari = isScrolling && isIOSWithSafari;
  const [showDrawer, setShowDrawer] = useState(false);
  window.addEventListener('payButtonScroll', () => setShowDrawer(false));
  const couponStatus = useSelector(state => selectCouponStatus(state, couponId));
  const isCouponValid = useMemo(() => couponStatus === COUPON_VALID, [couponStatus]);
  let betslipBaseHeight = BETSLIP_BASE_HEIGHT_MOBILE_ONGOING;
  if (isCouponValid) {
    betslipBaseHeight = BETSLIP_BASE_HEIGHT_MOBILE;
  }
  const betslipStyle = {
    '--betslip-base-height-mobile': "".concat(betslipBaseHeight, "px")
  };
  const drawerStyle = {
    '--betslip-base-height-mobile': "".concat(betslipBaseHeight, "px")
  };
  if (!showDrawer) {
    drawerStyle['--drawer-tab-height'] = "".concat(DRAWER_TAB_HEIGHT, "px");
  }
  const handleOpen = () => {
    setShowDrawer(true);
  };
  const handleOpenOrClose = event => {
    event.stopPropagation();
    setShowDrawer(!showDrawer);
  };
  useEffect(() => {
    if (betslipForceType === betslipForceTypes.BETSLIP) {
      gsap.to(betslipContainerRef.current, {
        y: 0,
        ease: 'power1.in',
        duration: 0.3
      });
    } else {
      gsap.to(betslipContainerRef.current, {
        y: 160,
        ease: 'power1.out',
        duration: 0.3
      });
    }
  }, [betslipForceType]);
  useEffect(() => {
    if (showDrawer) {
      analytics.trackEvent({
        category: 'Nya tipsen',
        action: 'Coupon Overview',
        opt_label: displayName
      });
    }
  }, [displayName, showDrawer]);
  const showBetslipMenuTab = couponSignCount > 0 && isExtraSmallDevice;
  return React.createElement("section", {
    "aria-label": "Betslip",
    className: "pg_betslip_container",
    ref: betslipContainerRef
  }, isSmallDevice && betslipForceType === betslipForceTypes.BETSLIP && React.createElement(Drawer, {
    className: "pg_betslip_drawer",
    direction: "bottom",
    expandableAreaHeight: BETSLIP_BASE_HEIGHT_MOBILE,
    isOpen: showDrawer,
    onClickOverlay: () => setShowDrawer(false),
    style: _objectSpread(_objectSpread({}, drawerStyle), preventDrawerTransitionOnIOSWithSafari ? {
      transition: 'none'
    } : {})
  }, React.createElement(BetslipDrawerContent, {
    handleOpen: handleOpen,
    handleOpenOrClose: handleOpenOrClose,
    style: drawerStyle
  })), React.createElement("div", {
    className: "pg_tipsen_betslip",
    style: betslipStyle
  }, React.createElement(CenterToScreen, {
    className: "pg_tipsen_betslip__centered_container"
  }, isExtraSmallDevice && !showDrawer && React.createElement(MenuButton, null), React.createElement(Betslip, null, React.createElement(BetslipSelector, null)))), showBetslipMenuTab && React.createElement(BetslipButton, {
    isVisible: betslipForceType === betslipForceTypes.MENU
  }));
};
setGlobal('svs.components.tipsen.betslipRegistry.BetslipRegistry', BetslipRegistry);

 })(window);